.titleStyle{
    margin-top: 0.8em;
    margin-bottom: 0.5em;
}

.headerContainer{
    background-color: #f99229;
    width: 100%;
    height: 5em;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 1em;
}

.headerRight {
    float: right;
}

img{
    width: 135.5px;
    padding-top:1em;
    padding-left: 0.5em;
}
.titleText {
    color: white;
    text-align: center;
    padding-top: 0.5em;
    padding-right: 0.5em;
    text-decoration: none;
    font-size: 32px;
    font-weight: 600;
    line-height: 25px;
    text-shadow: -2px 2px 1px #607D8B;
    cursor: pointer;
}

.text{
    font-size: 28px;
    width: 250px;
    line-height: 33.04px;
    min-height: 110px;
}
.titleTextSmall{
    float: left;
    color: white;
    text-align: center;
    padding-right: 0.5em;
    text-decoration: none;
    font-size: 8px;
    font-weight: 600;
    text-shadow: -2px 2px 1px #607D8B;
}