Button{
    margin-top: 0.5em;
    margin-right: 0.5em;
}

.fullWidth{
    width:100%;
}

.textBox{
    width: 18em;
}