input#txtQuestion{
    width: 100%;
}
.small{
    font-size: 0.8em;
    color: crimson;
}

.btnNamePlate{
    margin-top:2em;
}

.alert-success{
    margin-top: 0.5rem;
}